@import "./variables.scss";
@import "./pages/modals.scss";
@import "~bootstrap/scss/bootstrap.scss";

@import "./fonts";

@import "./components/input";
@import "./components/buttons";
@import "./components/sidebar";
// @import "./components/calendar";
// @import "./components/appointment.scss";
@import "./components/navigations";
@import "./components/icons";
@import "./components/search-input";
@import "./components/tabs";
@import "./components/table";
@import "./components/badges";
@import "./components/modal";
@import "./components/loader";
// @import "./components/therapist-slot-modal.scss";

@import "./pages/users/users.scss";

@import "./pages/CMS/CMS.scss";
@import "./pages/CMS/CMSBuilder.scss";
@import "./pages/CMS/CMSwebview.scss";

@import "./pages/questions/addQuestions";
@import "./pages/questions/questionBuilder";
@import "./pages/questions/questionModule";
@import "./pages/questions/preview.scss";
@import "./pages/questions/questions";
@import "./pages/questions/textChoice";

@import "./pages/scores/createScore";
@import "./pages/scores/scorePreview";
@import "./pages/scores/scores";
// @import "./pages/client-list";
@import "./pages/chat";
@import "./pages/newDashboard.scss";
// @import "./pages/forum.scss";
@import "./pages/document.scss";
@import "./pages/card.scss";
@import "./pages/study/phase.scss";

@import "./pages/auth-layout";

@import "./pages/CRFs/crfs.scss";

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.bg-primary-blue {
  background-color: #00b9fc; //$primary-color;
}
//Font sizes
.font-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.font-m {
  font-size: 1rem;
  line-height: 1.5rem;
}
.font-ml {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.font-l {
  font-size: 1.5rem;
  line-height: 2rem;
}
//Font weights
.fw-semibold {
  font-weight: 600;
}
.fw-bold {
  font-weight: bold;
}
.fw-medium {
  font-weight: 500;
}
.input-error {
  margin-top: 4px;
  color: $red-color;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  min-height: 20px;
}
.spaced-error {
  margin-top: 5px;
  min-height: 20px;
}
a.link-primary {
  color: $primary-color;
  &:hover {
    color: $primary-color;
  }
}
a.link-normal {
  color: #373737;
  &:hover {
    color: $primary-color;
  }
}
.no-underline {
  text-decoration: none;
}
.mw-400 {
  max-width: 400px;
}

.content.right-panel {
  margin-top: $navbar-height;
  padding: 3.5rem 1.5rem;
  @media (min-width: 768px) {
    padding: 2.5rem;
  }
}
.text-prime {
  color: $text-color;
}

.text-secondary {
  color: $text-color-2;
}

.text-gray {
  color: $text-gray;
}
.text-red {
  color: $red-color;
}
.large-hero-icon {
  width: 24px;
  height: 24px;
}
.hero-icon {
  width: 20px;
  height: 20px;
}
.hero-small-icon {
  width: 16px;
  height: 16px;
}
.mb-l {
  margin-bottom: 40px;
}
/*style for scrollbar  */
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

body,
* {
  box-sizing: border-box;
}

/* total width */
::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
  height: 4px;
}
/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
  // border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(11, 11, 11, 0.1);
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-light {
  border-color: $color-light-white;
}
.singer-layout {
  .ant-layout {
    background-color: $bg-background;
    min-height: 100vh;
    margin-left: 0;
    @media (min-width: 767px) {
      margin-left: 80px;
    }
  }
}

.cp {
  cursor: pointer;
}
.theme-white {
  &.singer-layout .ant-layout {
    background-color: $white;
  }
}
.card-holder {
  text-decoration: none;
  color: inherit;
}
.upload-wrapper {
  .upload-wrapper .ant-input {
    background-color: $color-gray-3;
  }
}

.input-control-bar .progressbar {
  li {
    display: inline-block;
  }
  li.active {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    color: #999999;
    cursor: pointer;
  }
  li.active:hover {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    color: #000;
    cursor: pointer;
    // text-shadow: 10px 10px 10px #000000;
  }

  li.active:before {
    content: "";
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0px auto 10px auto;
    border-radius: 50%;
    background-color: #000;
  }
  li.active::before {
    content: "";
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0px auto 10px auto;
    border-radius: 50%;
    background-color: #000;
  }
  // li:before:hover {
  //   content: "";
  //   width: 30px;
  //   height: 30px;
  //   border: 1px solid #ddd;
  //   display: block;
  //   text-align: center;
  //   margin: 0px auto 10px auto;
  //   border-radius: 50%;
  //   background-color: #000;
  //   box-shadow: 0 6px 20px 0 black, 0 6px 20px 0 black;
  // }

  li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #000;
    top: 15px;
    left: -50%;
  }

  li.active:first-child:after {
    content: none;
  }
  li.activated {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    color: #000;
  }
  li.activated:before {
    content: "";
    width: 30px;
    height: 30px;
    border: 3px solid #ffffff;
    display: block;
    text-align: center;
    margin: 0px auto 10px auto;
    border-radius: 50%;
    background-color: #000000;
    color: #000000;
    box-shadow: 0 6px 20px 0 black, 0 6px 20px 0 black;
  }

  li.activated:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #000;
    top: 15px;
    left: -50%;
  }

  li.activated:first-child:after {
    content: none;
  }
}
.form-ant-picker {
  padding: 0px 10px;
  input {
    border: none;
    font-size: 1.125rem;
    height: 2.25rem;
  }
}
.shadow-s {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}
.color-primary {
  color: $primary-color !important;
}
.shadow-lg {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16) !important;
}
.cp {
  cursor: pointer;
}
.edit-profile {
  max-width: 700px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(135 136 249 / 40%);
  border-radius: 4px;
}
.disabled-div-qb {
  pointer-events: none;
}
.ant-spin {
  color: $primary-color;
  .ant-spin-dot-item {
    background-color: $primary-color;
  }
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: text-bottom;
}
a {
  color: $primary-color;
  text-decoration: none;
}
a:hover {
  color: $primary-color;
}
.img-responsive {
  width: 100%;
}
.overflow-hover {
  overflow-y: hidden;
  &:hover {
    overflow-y: auto;
  }
}
.singer-dropdown {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
    }
  }
}

.singer-input {
  height: 44px;
  line-height: 44px;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
}

.custom-select-field {
  .ant-select-selector {
    border-radius: 16px;
  }
  &.ant-select.ant-select-lg {
    .ant-select-selector {
      border-radius: 16px;
    }
    &.left-none .ant-select-selector {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.ant-tag {
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  .anticon-close {
    margin-left: 5px;
    font-size: 14px;
  }
}

.content-header {
  position: fixed;
  top: 60px;
  left: 80px;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 80px;
  width: calc(100% - 80px);
  border-bottom: 1px solid $border-color;
  background-color: white;
  padding: 0px 20px;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .backdrop-close {
    position: fixed;
    right: 20px;
    top: 20px;
    font-size: 24px;
    color: white;
  }
  .view-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.singer-table-pagination {
  height: 76px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  &.ant-pagination {
    margin: 0px;
    margin-bottom: 20px;
    padding: 20px;
  }
  .ant-pagination-total-text {
    order: 2;
    margin-left: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    display: flex;
    align-items: center;
    &:hover {
      color: #4b5563;
    }
  }
  .ant-pagination-item,
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    display: none;
  }
  .pagination-icon {
    color: $text-color-2;
    font-size: 20px;
    stroke-width: 60;
    stroke: $text-color-2;
  }
  .ant-pagination-disabled .pagination-icon {
    opacity: 0.5;
  }
}

.singer-table-pagination-with-pages {
  height: 76px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  &.ant-pagination {
    margin: 0px;
    margin-bottom: 20px;
    padding: 20px;
  }
  .ant-pagination-total-text {
    order: 2;
    margin-left: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    display: flex;
    align-items: center;
    &:hover {
      color: #4b5563;
    }
  }
  .pagination-icon {
    color: $text-color-2;
    font-size: 20px;
    stroke-width: 60;
    stroke: $text-color-2;
  }
  .ant-pagination-disabled .pagination-icon {
    opacity: 0.5;
  }
}

.mw-70 {
  max-width: 70%;
}
.disabled {
  cursor: not-allowed;
}
.ant-dropdown-menu-item {
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }
}

.quill-container {
  .ql-toolbar.ql-snow {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border: 1px solid #e5e7eb;
  }
  .ql-container.ql-snow {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid #e5e7eb;
    .ql-editor {
      min-height: 150px;
    }
  }
}
.ant-image-preview-content {
  .ant-image-preview-operations {
    .ant-image-preview-operations-operation {
      color: white;
    }
  }
}
.ant-image-mask-info {
  display: flex;
  align-items: center;
}

.participant-table {
  ::-webkit-scrollbar {
    height: 8px;
  }
}
.site-selector {
  .ant-select-selector {
    padding: 4px;
  }
}
