.question-preview-container {
  margin: -2.5rem;
  padding-top: 80px;
  width: 75%;
  .preview-container {
    padding: 20px;
    .question-preview-block {
      border: 1px solid #e5e7eb;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      background: #ffffff;
      margin-bottom: 10px;
      padding: 12px;
      .question-number {
        width: 33px;
        height: 33px;
        background-color: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        margin-right: 1rem;
      }
      .question-title {
        flex: 1;
        min-width: 0px;
      }
      .question-action-container {
        margin-left: 60px;
      }
      .preview-choice {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .ant-checkbox-wrapper {
          margin-left: 0px;
        }
        .ant-radio-wrapper,
        .ant-checkbox-wrapper {
          border: 1px solid #d4d4d8;
          border-radius: 16px;
          padding: 8px;
          min-width: 200px;
          min-height: 50px;
          display: flex;
          align-items: center;
          & > span {
            display: flex;
            align-items: center;
          }
          &.ant-radio-wrapper-checked,
          &.ant-checkbox-wrapper-checked {
            border: 1px solid $primary-color;
            background-color: #e4c7f2;
          }
          .ant-radio,
          .ant-checkbox {
            display: none;
          }
          .alpha-text {
            width: 24px;
            height: 24px;
            border: 1px solid $primary-color;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .choice-text {
            flex: 1;
            min-width: 0px;
          }
          .choice-input {
            flex: 1;
            min-width: 0px;
            max-width: 400px;
          }
        }
      }
      .preview-text-field {
        margin-bottom: 10px;
        max-width: 80%;
        .ant-input {
          border-radius: 16px;
          resize: none;
          border: 1px solid #e5e7eb;
        }
        .ant-input-textarea-show-count::after {
          font-weight: 500;
        }
        .ant-picker {
          height: 40px;
          border-radius: 16px;
          border: 1px solid #e5e7eb;
          max-width: 50%;
        }
      }
      .preview-number {
        height: 40px;
        border: 1px solid #e5e7eb;
        border-radius: 16px;
        line-height: 40px;
      }
      .preview-file {
        max-width: 80%;
        height: 100px;
        background: #e4c7f2;
        border: 2px dashed $primary-color;
        border-radius: 20px;
      }
      .question-attachment {
        margin-bottom: 20px;
        margin-left: 60px;
        max-height: 400px;
        max-width: 400px;
        border-radius: 16px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .preview-likert {
        width: 80%;
        margin: 0;
        padding: 0 0 35px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 11px;
          display: block;
          background-color: #efefef;
          height: 4px;
          width: 100%;
        }
        .likert-option {
          text-align: center;
          vertical-align: top;
          .ant-radio-wrapper {
            display: block;
            position: relative;
            top: -5px;
          }
        }
      }
      .grid-preview {
        display: block;
        overflow: auto;
        td,
        th {
          min-width: 200px;
          border: 1px solid $border-color;
          text-align: center;
          height: 50px;
        }
        th {
          font-weight: 500;
        }
        .grid-selector {
          .ant-select-selector {
            border-radius: 16px;
          }
        }
        .grid-datepicker {
          border-radius: 16px;
          height: 40px;
        }
      }
    }
    .preview-statement {
      background: #e4c7f2;
      border-radius: 4px;
      width: 100%;
      margin: 15px 0px;
      padding: 10px;
    }
  }
}
